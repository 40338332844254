
import axios from '../utils/request/request'
const api = {
    // 注册
    register: params => axios.post('/pcregister', params),
    // 登录
    login: params => axios.post('/pclogin', params),
    // 发送验证码
    sendCode: params => axios.post('/sms/sendCode', params),
    // 忘记密码
    forgetPassword: params => axios.post('/pc_forget', params),
    // 左侧我的会话列表
    myChatListLeft: params => axios.post('/gpt_chat/myleft', params),
    // 我的会话列表
    myChatList: params => axios.post('/gpt_chat/my', params),
    // 创建提问id
    createAskId: params => axios.post('/gpt_chat/setstream', params),
    // 读取数据流
    getstream: params => axios.post('/gpt_chat/getstream', params),
    // 会话详情
    getChatDetail: params => axios.post('/gpt_chat/detail', params),
    // 发送消息错误报告
    sendMessageError: params => axios.post('/gpt_chat/errormsg', params),
    // 模型列表
    getFormerlIst: params => axios.post('/gpt_chat/formerlist', params),
    // 修改会话标题
    changetitle: params => axios.post('/gpt_chat/changetitle', params),
    // 删除会话
    delChat: params => axios.post('/gpt_chat/del', params),
    // 论坛
    getForumList: params => axios.post('/gpt_chat/forumchat', params),
    // 分享至论坛
    chatShare: params => axios.post('/gpt_chat/changeshare', params),
    // 魔板列表
    templateList: params => axios.post('/gpt_chat/templatelist', params),
    // 魔板详情
    templateDetail: params => axios.post('/gpt_chat/templatedetail', params),
    // 会话操作
    operate: params => axios.post('/gpt_chat/chatop', params),
    // 关注
    follow: params => axios.post('/user/follow', params),
    // 分享链接获取详情接口
    shareInfo: params => axios.post('/share/chat', params),
    // 问答
    chatAsk: params => axios.post('/gpt_chat/askapi', params),
    // user信息
    userInfo: params => axios.post('/user', params),
    // 积分列表
    scoreList: params => axios.post('/user/scorelist', params),
    // 我的问答
    myChat: params => axios.post('/gpt_chat/mychat', params),
    // 问答风格
    outputStyle: params => axios.post('/output_style', params),
    // 通知消息
    noticeList: params => axios.post('/notice', params),
    // 消息已读
    readNotice: params => axios.post('/notice/read', params),
    // 修改个人信息
    changeMobile: params => axios.post('/user/changemobile', params),
    // 修改昵称
    changeNickName: params => axios.post('/user/changenickname', params),
    // 上传文件
    upload: params => axios.post('/upload', params),
    // 修改头像
    changeAvatar: params => axios.post('/user/changeavatar', params),
    // 更新用户信息
    changUsrInfo: params => axios.post('/user/change', params),
    // 分享二维码
    shareQrcode: params => axios.post('/qr_code', params),
    // 关注列表
    followList: params => axios.post('/user/myfollow', params),
    // 粉丝列表
    fansList: params => axios.post('/user/myfans', params),
    // 充值
    rechargeList: params => axios.post('/gpt_chat/recharge', params),
    // 支付
    pay: params => axios.post('/pay/pc', params),
    // 扫码
    getWxInfo: params => axios.post('/pclogin/show_wxcode', params),
    // 获取用户信息
    getWxUserInfo: params => axios.post('/pclogin/wx_callback', params),
    // 配置
    getConfig: params => axios.post('/config', params),
    // 免登录信息
    getLoginInfo: params => axios.post('/test/log', params),
    // 支付结果
    payResult: params => axios.post('/order/check', params),
    // 首页模板
    indexTemplate: params => axios.post('/gpt_chat/template', params),
    // 游客登录
    getSessionId: params => axios.post('/user/settourist', params),
    // 未读消息
    unread: params => axios.post('/noticeunread', params),
    // 个人主页信息
    userHome: params => axios.post('/user/homeinfo', params),
    // 用户行为
    userBehavior: params => axios.post('/user/addlog', params),
    // 继续提问
    continueAsk: params => axios.post('/gpt_chat/continueask', params)
}
export default api