import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
export default createStore({
    state: {
        currentChatId: '',
        isNewChat: false,
        isPrinting: false,
        sendMessageInfo: {
        },
        userInfo: {},
        showLogin: false,
        showTip: true, // 是否展示微信登录框弹出的提示信息“请先登录”
        showTemplate: true, // 是否展示模板
        unReads: 0,
        payDialogState: false,
        getBingBiState: false,
        refresh: false
    },
    getters: {},
    mutations: {
        setCurrentChatId(state, data) {
            state.currentChatId = data
        },
        setNewChat(state, data) {
            state.isNewChat = data
        },
        setPrinting(state, data) {
            state.isPrinting = data
        },
        setSendMesssageInfo(state, data) {
            state.sendMessageInfo = data
        },
        setUserInfo(state, data) {
            state.userInfo = data
        },
        setShowLogin(state, data) {
            if (typeof data == 'object') {
                state.showTip = data.tip
                state.showLogin = data.state    
            } else {
                state.showLogin = data
                state.showTip = true
            }
        },
        setShowTemplate(state, data) {
            state.showTemplate = data
        },
        setUnReads(state, data) {
            state.unReads = data
        },
        setPayDialogState(state, data) {        
            state.payDialogState = data
        },
        setGetBingBiState(state, data) {
            state.getBingBiState = data
        },
        setRefresh(state, data) {  
            state.refresh = data
        }
    },
    plugins: [
        // 持久化存储
        createPersistedstate(
          {
            key: 'BingshanStorage',
            paths: ['userInfo']
          })
      ]
})