<template>
  <router-view/>
</template>
<style lang="scss">
body,html{
  width: 100%;
  min-width: 1360px;
}
*{
  margin: 0;
  padding: 0;
}
.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::-webkit-scrollbar{
  width:6px;
  height:5px;
  background-color:#fff;
}
::-webkit-scrollbar-track{
  // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
  border-radius:10px;
  background-color:#fff;
}
::-webkit-scrollbar-thumb{
  border-radius:10px;
  background-color:#a0a5ab73;
}
::deep.el-avatar{
  background: #409EFF;
  color: #fff;
}
.el-avatar{
    background: rgba(255,255,255,0) !important;
    // color: #fff !important;
}
.el-dialog__body{
    padding: 20px !important;
}
.el-dialog__footer {
  text-align: center !important;
}
</style>