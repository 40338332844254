<template>
    <div class="noData">
        <img src="@/assets/noData.png" alt="">
        <p><slot></slot></p>
    </div>
</template>
<style lang="scss" scoped>
.noData{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
        font-size: 14px;
        letter-spacing: 3px;
        color: #999;
    }
}
</style>