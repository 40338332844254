import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Api from './api/api'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import Vuex from './store/vuex'
import NoData from '@/components/layout/NoData.vue'

const app = createApp(App)
app.use(router)
.use(ElementPlus)
.use(Vuex)
.mount('#app')

app.component('NoData', NoData)

app.config.globalProperties.$API = Api
window.$API = Api

import { ElNotification } from 'element-plus'
app.config.globalProperties.$Notice = ElNotification

var _hmt = _hmt || [];
window._hmt = _hmt; // 将_hmt挂载到window下
(function() {
    var hm = document.createElement('script');
    hm.src = 'https://hm.baidu.com/hm.js?b6c497d072c6541680180c017f549178';
    var s = document.getElementsByTagName('script')[0]; 
    s.parentNode.insertBefore(hm, s);
  })();