import { createRouter, createWebHashHistory } from 'vue-router'
import vuex from '@/store/vuex'
const routes = [
  {
    path: '/',
    component: () => import('@/views/index'),
    redirect: '/chat',
    children: [
      {
        path: '/chat',
        component: () => import('@/views/chat/chat')
      },
      {
        path: '/notice',
        component: () => import('@/views/notice/notice')
      },
      {
        path: '/forum',
        component: () => import('@/views/forum/forum')
      },
      {
        path: '/template',
        component: () => import('@/views/template/template')
      },
      {
        path: '/mine',
        component: () => import('@/views/mine/mine')
      }
    ]
  },
  {
    path: '/chatShare',
    component: () => import('@/views/chat/chatShare')
  },
  {
    path: '/login',
    component: () => import('@/views/userActions/login')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 路由跳转检测token, 如果即将跳转到我的页面并且没有token则展示登录弹框
router.beforeEach((to, from, next) => {
  let TOKEN = vuex.state.userInfo.token ?? null
  if (to.path == '/mine' && ! TOKEN) {
      vuex.commit('setShowLogin', true)
      next({ path: '/chat' })
  } else {
      next()
  }
  if (to.path != '/chat') {
    vuex.commit('setShowTemplate', false)
  } else {
    vuex.commit('setShowTemplate', true)
  }
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
}
})
export default router
